angular.module('hcap-analytics').controller('LandingConsentCtrl', [
  '$scope',
  'UserService',
  'StateService',
  'AlertService',
  '$http',
  '$state',
  '$location',
  '$window',
  '$timeout',
function(
  $scope,
  UserService,
  StateService,
  AlertService,
  $http,
  $state,
  $location,
  $window,
  $timeout,
) {
  $scope.data = {
    page: 0,
    profile: {
      name: 'SPABC User',
    },
    consentText: null,
    errorMessage: null,
    busy: false,
    years: [],
  };

  let currentYear = moment().year();
  for(let i=0; i < 100; i++) {
    $scope.data.years.push(currentYear);
    currentYear--;
  }

  $scope.onEmailChange = function() {
    if (!$scope.data.profile.email) {
      return;
    }

    UserService.validateEmail($scope.data.profile.email).then(response => {
      if (!response.exists) {
        $scope.data.busy = false;
        $scope.data.errorMessage = null;
      } else {
        $scope.data.busy = true;
        $scope.data.errorMessage = $scope.errorMessage(response.message);
      }
    });
  };

  $scope.onSubmit = function() {
    if ($scope.form.$invalid) {
      return;
    }

    $scope.data.busy = true;

    if (0 === $scope.data.page) {
      if ($scope.data.profile.consent !== 'Yes') {
        $state.go('spabc-finish');
      } else {
        $timeout(() => {
          ++$scope.data.page;
          $scope.data.busy = false;
        });
      }
    } else {
      $scope.data.errorMessage = null;

      UserService.signUp({
        ...$scope.data.profile,
        src: localStorage.getItem('src'),
      }, function(user, errorMessage) {
        $scope.data.busy = false;

        if (!user) {
          $scope.data.errorMessage = $scope.errorMessage(errorMessage);
        } else {
          StateService.land();
        }
      }, true);
    }
  };

  $scope.submitDisabled = function() {
    if (0 === $scope.data.page) {
      return false;
    } else {
      return $scope.data.busy;
    }
  };

  $scope.errorMessage = function(errorMessage) {
    if (errorMessage.match(/exists/)) {
      return 'An account with that email address already exists. Please use another personal email address.';
    } else {
      return errorMessage;
    }
  };

}]);
